import { mapActions } from 'vuex';

export const withShareSpace = {
    methods: {
        ...mapActions({
            fetchAddShareSpace: 'addShareSpace',
            handleDeleteShareSpace: 'deleteShareSpace',
            handleUpdateShareSpace: 'updateShareSpace'
        }),
        async addShareSpace(shareSpace) {
            this.$toast.clear();
            const shareSpaces = this.form.shareSpaces;
            if (this.pageUpdateAction) {
                const response = await this.fetchAddShareSpace({ form: { title: shareSpace }, invoiceConnectorId: this.form.id });
                shareSpaces.unshift({ ...response.data });
                this.toastMessage(response.message, 'success');
            } else {
                shareSpaces.unshift({ id: Date.now(), title: shareSpace, boards: [] });
                this.form.shareSpaces = shareSpaces;
            }
        },
        deleteShareSpace(selectedShareSpace) {
            if (this.pageUpdateAction) {
                this.$toast.clear();
                this.handleDeleteShareSpace({ id: selectedShareSpace.id })
                    .then(response => this.toastMessage(response.data.message, 'success'));
                this.deleteSpace(selectedShareSpace);
            } else {
                this.deleteSpace(selectedShareSpace);
            }
        },
        deleteSpace(selectedShareSpace) {
            this.form.shareSpaces = this.form.shareSpaces.filter(shareSpace => Number(shareSpace.id) !== Number(selectedShareSpace.id));
        },
        updateShareSpace({ value, id }) {
            this.$toast.clear();
            if (this.pageUpdateAction) {
                this.handleUpdateShareSpace({ title: value, id })
                    .then(response => this.toastMessage(response.data.message, 'success'));
                this.updateSpace(value, id);
            } else {
                this.updateSpace(value, id);
            }
        },
        updateSpace(value, id) {
            this.form.shareSpaces.filter((item) => {
                if (item.id === id) {
                    item.title = value;
                }
            });
        }
    }
};
