<template>
    <tbody class="bg-white divide-y divide-gray-100">
    <Row
        v-for="invoiceConnector in dataTable"
        :key="invoiceConnector.id"
        :invoiceConnector="invoiceConnector"
        @handleOpenDeleteItemModal="$emit('handleOpenDeleteInvoiceConnectorModal', invoiceConnector.id)"
        @handleSetSelectedItem="handleSetSelectedItem"
        :selectedItem="String(selectedItem)"
    />
    </tbody>
</template>

<script>
import EditActions from '@/components/Table/EditActions/EditActions';
import Row from '@/components/InvoiceConnectors/Table/TableBody/Row/Row';

export default {
    name: 'TableBody',
    components: {
        EditActions,
        Row
    },
    props: {
        dataTable: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            selectedItem: ''
        }
    },
    methods: {
        handleSetSelectedItem(invoiceConnectorId) {
            this.selectedItem = invoiceConnectorId;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
