<template>
  <div className="comment">
    <div className="media">
      <figure className="media-left">
        <p className="inline-block h-avatar w-avatar rounded-full">
          <img v-if="comment.user" :src="comment.user | getCommentAvatar" alt="">
          <img v-else :src="comment.author | getCommentAvatar" alt="">
        </p>
      </figure>
      <div className="media-content">
        <div className="content">
          <strong v-if="comment.user">{{ comment.user | getCommentAuthor }}</strong>
          <strong v-else>{{ comment.author | getCommentAuthor }}</strong>
          <small>{{ comment.ago }}</small>
          <div className="comment_text" v-html="comment.comment"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      required: true,
      'default': () => {
        return null
      }
    }
  },
  name: 'CommentList'
}
</script>

<style lang="scss">
@import '../../../sass/mixins';

.comment {
  margin-bottom: 30px;

  .media-left {
    margin: 0 13px 0 -3px;

    img {
      border-radius: 50%;
      height: 100%;
      object-fit: cover;
    }
  }

  .media-content {

    strong {
      margin-right: 5px;
    }
  }
}
</style>
