<template>
  <div v-if="!loading" class="mx-auto p-6 xl:max-w-full xl:grid xl:grid-cols-1 border-t border-b">
    <div class="xl:col-span-2 mb-8 xl:pr-8 xl:border-r xl:border-gray-200">
      <div class="grid grid-cols-1 gap-x-1 gap-y-10 sm:grid-cols-2">
        <div
            class="sm:col-span-1 gap"
            v-for="(item, key) in infoList">
          <template v-if="key === 'invoiceConnector.fields.invoiceConnectorUserSecret'">
            <div class="fieldTitle break-all">
              <MainFieldTitle :label="$t(key)" name="(Encrypted)"/>
            </div>
          </template>
          <template v-else-if="key === 'invoiceConnector.fields.fennoa.key'">
            <div class="fieldTitle max-h-full">
              <MainFieldTitle :label="$t(key)" name="(Encrypted)"/>
            </div>
          </template>
          <template v-else>
            <div class="fieldTitle max-h-full">
              <MainFieldTitle :label="$t(key)" :name="item"/>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import { mapActions, mapGetters } from 'vuex';
import { EventBus } from '@/eventBus/event-bus';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import CommentList from '@/components/InvoiceConnectors/CommentList/CommentList';
import MainFieldTitle from '@/components/Main/Form/MainFieldTitle/MainFieldTitle';
import FormTextArea from '@/components/Main/Form/FormTextArea/FormTextArea';
import CommentBox from '@/components/Main/CommentBox/CommentBox';
import RightAside from '@/components/InvoiceConnectors/RightAside/RightAside';
import InvoiceConnectorApartments from '@/components/Apartments/InvoiceConnectorApartments';
import { Attachments } from '@/mixins/Attachments';
import { withShareSpace } from '@/mixins/InvoiceConnectors/withShareSpace';
import FormTextAreaEditor from '@/components/Main/Form/FormTextArea/FormTextAreaEditor';
import { RequestInvoiceConnectorForm } from '@/mixins/InvoiceConnectors/RequestInvoiceConnectorForm';

export default {
  name: 'InvoiceConnector',
  mixins: [Attachments, RequestInvoiceConnectorForm, withShareSpace],
  components: {
    FormTextAreaEditor,
    RightAside,
    CommentBox,
    FormTextArea,
    MainFieldTitle,
    ErrorMessage,
    FormInput,
    CommentList,
    InvoiceConnectorApartments
  },
  data() {
    return {
      editable: false,
      loading: true,
      pageUpdateAction: true,
      prefix: 'invoiceConnector',
      form: {
        id: '',
        name: '',
        contact: '',
        address: '',
        description: '',
        apartments: [],
        comments: [],
        vendorId: '',
        vendorName: '',
        files: [],
        shareSpaces: [],
        invoicing_id: ''
      },
      attachedFiles: [],
      formErrors: {}
    };
  },
  computed: {
    ...mapGetters([
      'account',
      'invoiceConnector',
      'updateInvoiceConnectorSuccess',
      'invoiceConnectorResponseMessage'
    ]),

    infoList() {
      let infoList = {
        'invoiceConnector.fields.invoiceConnectorVendorName': this.form.vendor.name,
        '': null,
        'invoiceConnector.fields.invoiceConnectorName': this.form.name
      };

      if (this.form.vendor.code === 'fennoa') {
        infoList = { ...infoList, ...this.getFenoaInfoList() }
      } else if (this.form.vendor.code === 'netvisor') {
        infoList = { ...infoList, ...this.getNetvisorInfoList() }
      }

      return infoList;
    }
  },
  watch: {
    invoiceConnector(data) {
      this.form = { ...data };
      this.handleSetBreadCrumbItem(data.name);
      this.loading = false;
    },
    updateInvoiceConnectorSuccess() {
      this.editable = false;
      EventBus.$emit('formSubmitted', true);
      this.invoiceConnectorToastMessage(this.invoiceConnectorResponseMessage, 'success');
      this.handleSetBreadCrumbItem(this.form.name);
    }
  },
  mounted() {
    const { id } = this.$route.params;
    this.handleGetInvoiceConnector(id);
    this.form.id = id;
    EventBus.$on('handleEdit', editable => {
      this.$router.push({ name: 'invoiceConnector-edit', params: { id } });
    });
    EventBus.$on('handleSave', () => this.updateInvoiceConnector());

    this.handleSetBreadCrumbItems({ index: 0, to: '/invoice-connectors' });
  },
  methods: {
    ...mapActions({
      handleGetInvoiceConnector: 'getInvoiceConnector',
      handleSetBreadCrumbItem: 'setBreadCrumbItem',
      handleSetBreadCrumbItems: 'setBreadCrumbItems',
      handleResetBreadCrumbItems: 'resetBreadCrumbItems'
    }),
    handleInputChange(inputName, value) {
      this.formErrors[inputName] = [];
      this.form[inputName] = value;
    },
    handleChangeAssigneeInvoiceConnector(vendorId) {
      this.form.vendorId = vendorId;
    },
    handleAddAttachment(files) {
      this.attachedFiles = files;
      this.handleAddAttachments(files);
    },
    getFenoaInfoList() {
      return {
        'invoiceConnector.fields.fennoa.user': this.form.user_key,
        'invoiceConnector.fields.fennoa.key': this.form.user_secret
      };
    },
    getNetvisorInfoList() {
      return {
        'invoiceConnector.fields.invoiceConnectorUserKey': this.form.user_key,
        'invoiceConnector.fields.invoiceConnectorUserSecret': this.form.user_secret,
        'invoiceConnector.fields.invoiceConnectorPartnerId': this.form.partner_id,
        'invoiceConnector.fields.invoiceConnectorPartnerKey': this.form.partner_key,
        'invoiceConnector.fields.invoiceConnectorLanguage': this.form.language,
        'invoiceConnector.fields.invoiceConnectorOrganizationId': this.form.organization_id
      }
    }
  },
  destroyed() {
    EventBus.$off('handleSave');
    EventBus.$off('handleEdit');
    this.handleResetBreadCrumbItems();
    this.handleSetBreadCrumbItem();
    this.$toast.clear();
    this.$destroy();
  }
};
</script>

<style scoped>

</style>
