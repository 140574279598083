<template>
  <div class="align-middle inline-block min-w-full border-b border-gray-200">
    <table class="w-full table-fixed">
      <TableHead @handleSort="handleSort" />
      <TableBody @handleOpenDeleteInvoiceConnectorModal="$emit('handleOpenDeleteInvoiceConnectorModal', $event)" :dataTable="dataTable"/>
    </table>
  </div>
</template>

<script>
import TableHead from '@/components/InvoiceConnectors/Table/TableHead/TableHead';
import TableBody from '@/components/InvoiceConnectors/Table/TableBody/TableBody';

export default {
  name: 'Table',
  components: {
    TableHead,
    TableBody
  },
  props: {
    dataTable: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleSort(key, sorting) {
      this.$emit('handleSort', key, sorting);
    }
  }
}
</script>

<style scoped>

</style>
