import { makeFormData } from '@/helpers/formData';
import { mapActions, mapGetters } from 'vuex';

export const RequestInvoiceConnectorForm = {
    computed: {
        ...mapGetters([
            'createInvoiceConnectorSuccess',
            'createInvoiceConnectorError',
            'updateInvoiceConnectorError',
            'invoiceConnectorResponseMessage'
        ])
    },
    watch: {
        createInvoiceConnectorSuccess(newValue) {
            if (newValue) {
                this.invoiceConnectorToastMessage(this.invoiceConnectorResponseMessage, 'success');
                this.redirectToIndexPage();
            }
        },
        createInvoiceConnectorError(newValue) {
            this.handleRequestErrorMessage(newValue);
        },
        updateInvoiceConnectorError(newValue) {
            this.handleRequestErrorMessage(newValue);
        }
    },
    methods: {
        ...mapActions({
            handleCreateInvoiceConnector: 'createInvoiceConnector',
            handleUpdateInvoiceConnector: 'updateInvoiceConnector',
            fetchCreateApartment: 'addApartment',
            fetchUpdateApartment: 'updateApartment',
            fetchDelateApartment: 'deleteApartment'
        }),
        createRequestFormData() {
            return makeFormData({
                name: this.form.name,
                userKey: this.form.userKey,
                userSecret: this.form.userSecret,
                partnerId: this.form.partnerId,
                partnerKey: this.form.partnerKey,
                language: this.form.language,
                organizationId: this.form.organizationId,
                vendorId: this.form.vendorId
            });
        },
        attachSharedSpacesToFormData(formData) {
            this.form.shareSpaces.filter((space, index) => {
                formData.append(`shareSpaces[${index}]`, JSON.stringify({ space: space.title, boards: space.boards }));
            });
        },
        createInvoiceConnector() {
            const formData = this.createRequestFormData(true);
            //this.attachSharedSpacesToFormData(formData);
            this.handleCreateInvoiceConnector({ form: formData });
        },
        handleAddNewApartment(apartment) {
            this.fetchCreateApartment({ id:this.form.id, form: { apartment } }).then(({ data: { message, data } }) => {
            });
        },
        handleEditApartment(apartment) {
            this.fetchUpdateApartment({ id:this.form.id, apartment: { apartment } }).then(({ data: { message, data } }) => {
            });
        },
        handleDeleteApartment(apartment) {
            this.fetchDelateApartment({ id:this.form.id, apartmentId: apartment }).then(({ data: { message, data } }) => {
            });
        },
        updateInvoiceConnector(data) {
            const formData = this.createRequestFormData(false);
            formData.append('_method', 'PATCH');
            this.handleUpdateInvoiceConnector({ form: data, id: data.id });
        },
        handleRequestErrorMessage(isError) {
            if (isError) {
                const { message, errors } = this.invoiceConnectorResponseMessage;
                this.formErrors = { ...this.formErrors, ...errors };
                this.invoiceConnectorToastMessage(message, 'error');
            }
        },
        redirectToIndexPage() {
            this.$router.push('/invoice-connectors');
        },
        invoiceConnectorToastMessage(message, type) {
            this.$toast.clear();
            this.$toast.open({ message, type })
        }
    }
}
