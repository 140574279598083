<template>
  <div>
    <div v-if="!editable" class="fieldTitle">
      <SelectOptionLabel
          :label="$t('invoiceConnector.fields.vendor')"
          :name="searchValue"
      />
    </div>
    <div v-else class="w-full flex items-start justify-start flex-col gap-2">
      <label class="text-sm font-medium text-gray-500">{{ $t('invoiceConnector.fields.selectVendor') }}</label>
      <div class="w-full relative" v-click-outside="closeDropdown">
        <div class="w-full">
          <input
              @click="handleOpenDropDown"
              @input="handleSearch"
              v-model="searchValue"
              :disabled="!editable"
              type="text"
              autocomplete="off"
              class="w-full cursor-pointer h-formInput bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-gray-300 focus:border-gray-300 focus:ring-opacity-0 sm:text-sm">
          <FormInputArrowIcon />
        </div>
        <div class="absolute w-full rounded-md bg-white">
          <ul v-if="isOpen && vendorsList.length"
              class="absolute z-50 w-full bg-white max-h-60 rounded-md py-1 ring-opacity-5 overflow-auto focus:outline-none text-sm border border-gray-300">

            <li v-for="vendor in vendorsList"
                :class="['cursor-pointer select-none relative py-2 pl-8 pr-4', {
                         'text-white bg-primary': vendor.id === selectedVendor.id,
                         'text-gray-900 hover:bg-gray-50': vendor.id !== selectedVendor.id
                }]"
                :key="vendor.id"
                @click="handleSelectVendor(vendor.id)"
            >
              <span class="font-normal block truncate">{{ vendor.name }}</span>
              <span
                  v-if="vendor.id === selectedVendor.id"
                  class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                   :fill="vendor.id === selectedVendor.id ? 'white' : 'currentColor'">
                <path fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"/>
              </svg>
            </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ClickOutside from 'vue-click-outside'
  import FormInputArrowIcon from '@/components/Icons/FormInputArrowIcon';
  import SelectOptionLabel from '@/components/SelectOptions/SelectOptionLabel';

  export default {
    name: 'AssigneeVendors',
    components: {
      SelectOptionLabel,
      FormInputArrowIcon
    },
    directives: {
      ClickOutside
    },
    props: {
      defaultValue: {
        type: String
      },
      editable: {
        type: Boolean,
        'default': true
    },
      vendors: {
        type: Array
      }
  },
  data() {
    return {
      isOpen: false,
      searchValue: '',
      vendorList: [],
      vendorsList: [],
      selectedVendor: {}
    }
  },

  mounted() {
     this.$store.dispatch('getVendors');
  },
  watch: {
    assigneeVendors(newValue) {
      this.vendors = newValue;
    },
   vendors(newValue) {
     this.vendorsList = newValue;
     this.handleSetDefaultValue();
   },
    editable(newValue) {
      !newValue && this.handleSetDefaultValue();
    }
  },
  methods: {
    closeDropdown() {
      this.isOpen = false;
    },
    handleOpenDropDown() {
      this.isOpen = !this.isOpen;
      this.vendorsList = this.vendors;
    },
    handleSearch() {
      this.vendorsList = this.vendors.filter((vendor) => {
        if (vendor.name.toLowerCase().includes(this.searchValue.toLowerCase())) {
          return vendor;
        }
      });
    },
    handleSetDefaultValue() {
      if (this.defaultValue) {
        this.selectedVendor = this.vendorsList.find(vendor => String(vendor.id) === this.defaultValue);
      }
      this.searchValue = this.selectedVendor.name;
    },
    handleSelectVendor(id) {
      const selectedVendor = this.vendors.find(vendor => vendor.id === id);
      this.searchValue = selectedVendor.name;
      this.selectedVendor = selectedVendor;
      this.isOpen = false;
      this.$emit('setVendor', selectedVendor);
    }
  }
}
</script>

<style scoped>
</style>
