<template>
    <AppLayout>
        <router-view />
    </AppLayout>
</template>

<script>
import AppLayout from '@/components/Layout/AppLayout';

export default {
    name: 'InvoiceConnectors',
    components: {
        AppLayout
    }
}
</script>

<style scoped>

</style>
