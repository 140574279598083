<template>
  <div>
    <div class="w-full flex items-start justify-start flex-col gap-1 mt-1">
      <div class="w-full border-b">
        <h3 class="text-sm font-medium text-gray-500 pb-2">{{ $t('apartment.apartments') }}</h3>
      </div>
    </div>

    <AddApartments
        v-if="!isPublicPage"
        @handleAddNewApartment="$emit('handleAddNewApartment', $event)"
    />
    <div v-for="apartment in apartments">
      <EditOrDeleteApartment
          :item="apartment"
          @handleEditItem="$emit('handleEditApartment', $event)"
          @handleOpenDeleteItemModal="$emit('handleDeleteApartment', $event)"
      ></EditOrDeleteApartment>
    </div>
  </div>
</template>

<script>
import AddApartments from '@/components/Apartments/AddApartments';
import EditOrDeleteApartment from '@/components/Apartments/EditOrDeleteApartment';

export default {
  name: 'InvoiceConnectorApartments',
  props: {
    defaultMembers: {
      type: Array,
      'default': Array
    },
    editable: {
      type: Boolean,
      'default': true
    },
    pageUpdateAction: {
      type: Boolean
    },
    isPublicPage: {
      type: Boolean,
      'default': false
    },
    apartments: {
      type: Array,
      'default': []
    },
    label: {
      type: String,
      required: true
    }
  },
  components:{
    AddApartments,
    EditOrDeleteApartment
  },
  data() {
    return {
      searchValue:''

    }
  }
}
</script>

<style scoped>

</style>
