<template>
  <div v-if="!loading" class="mx-auto p-6 xl:max-w-full xl:grid  border-t border-b">
    <div class="xl:col-span-2 mb-8 xl:pr-8 xl:border-gray-200 space-y-8">
      <aside class="w-full">
        <RightAside
            @setVendor="setVendor"
            :editable="true"
            :form="form"
            :prefix="prefix"
            :pageUpdateAction="pageUpdateAction"
            :vendors="vendors"
        />
      </aside>
      <template v-if="vendors.length ">
      <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <!--        {{ this.$router.currentRoute.path }}-->
        <template v-if="form.vendorId === 2">
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('invoiceConnector.fields.invoiceConnectorName')"
                inputName="name"
                :value="form.name"
                :errorMessage="formErrors.name"
                @handleInputChange="handleInputChange"
            />
            <span :class="v$.form.name.$invalid ? 'invalid':''" v-if="v$.form.name.$dirty && v$.form.name.$invalid">
<!--              {{v$.form.name.required.$message}}-->
              {{ $t('invoiceConnector.validation.theNameFieldIsRequired') }}
          </span>
          </div>
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('invoiceConnector.fields.userKey')"
                inputName="userKey"
                :value="form.userKey"
                @handleInputChange="handleInputChange"
            />
            <span :class="v$.form.userKey.$invalid ? 'invalid':''"
                  v-if="v$.form.userKey.$dirty && v$.form.userKey.$invalid">
<!--            {{v$.form.userKey.required.$message}}-->
            {{ $t('invoiceConnector.validation.theUserKeyFieldIsRequired') }}
          </span>
          </div>
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('invoiceConnector.fields.userSecret')"
                inputName="userSecret"
                :value="form.userSecret"
                @handleInputChange="handleInputChange"
                :placeholder="isEditPage ? '(Encrypted)' : ''"
            />
            <span :class="v$.form.userSecret.$invalid ? 'invalid':''"
                  v-if="v$.form.userSecret.$dirty && v$.form.userSecret.$invalid">
<!--            {{v$.form.userSecret.required.$message}}-->
            {{ $t('invoiceConnector.validation.theUserSecretFieldIsRequired') }}
          </span>
          </div>
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('invoiceConnector.fields.partnerId')"
                inputName="partnerId"
                :value="form.partnerId"
                @handleInputChange="handleInputChange"
            />
            <span :class="v$.form.partnerId.$invalid ? 'invalid':''"
                  v-if="v$.form.partnerId.$dirty && v$.form.partnerId.$invalid">
<!--            {{v$.form.partnerId.required.$message}}-->
            {{ $t('invoiceConnector.validation.thePartnerIdFieldIsRequired') }}
          </span>
          </div>
          <div class="sm:1">
            <FormInput
                :label="$t('invoiceConnector.fields.partnerKey')"
                inputName="partnerKey"
                :value="form.partnerKey"
                @handleInputChange="handleInputChange"
            />
            <span :class="v$.form.partnerKey.$invalid ? 'invalid':''"
                  v-if="v$.form.partnerKey.$dirty && v$.form.partnerKey.$invalid">
<!--            {{v$.form.partnerKey.required.$message}}-->
            {{ $t('invoiceConnector.validation.thePartnerKeyFieldIsRequired') }}
          </span>
          </div>
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('invoiceConnector.fields.language')"
                inputName="language"
                :value="form.language"
                @handleInputChange="handleInputChange"
            />
            <span :class="v$.form.language.$invalid ? 'invalid':''" class=""
                  v-if="v$.form.language.$dirty && v$.form.language.$invalid">
<!--            {{v$.form.language.required.$message}}-->
            {{ $t('invoiceConnector.validation.theLanguageFieldIsRequired') }}
          </span>
          </div>
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('invoiceConnector.fields.organizationId')"
                inputName="organizationId"
                :value="form.organizationId"
                @handleInputChange="handleInputChange"
            />
            <span :class="v$.form.organizationId.$invalid ? 'invalid':''"
                  v-if="v$.form.organizationId.$dirty && v$.form.organizationId.$invalid">
<!--            {{v$.form.organizationId.required.$message}}-->
              {{ $t('invoiceConnector.validation.theOrganizationIdFieldIsRequired') }}
          </span>
          </div>
        </template>
        <!--        <template v-else-if="this.$router.currentRoute.path === ''">-->
        <!--          -->
        <!--        </template>-->
        <template v-else>
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('invoiceConnector.fields.fennoa.name')"
                inputName="name"
                :value="form.name"
                :errorMessage="formErrors.name"
                @handleInputChange="handleInputChange"
            />
            <span :class="v$.form.name.$invalid ? 'invalid':''" v-if="v$.form.name.$dirty && v$.form.name.$invalid">
<!--            {{v$.form.name.required.$message}}-->
              {{ $t('invoiceConnector.validation.theNameFieldIsRequired') }}
          </span>
          </div>
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('invoiceConnector.fields.fennoa.user')"
                inputName="userKey"
                :value="form.userKey"
                @handleInputChange="handleInputChange"
            />
            <span :class="v$.form.userKey.$invalid ? 'invalid':''" v-if="v$.form.userKey.$dirty && v$.form.userKey.$invalid">
<!--            {{v$.form.userKey.required.$message}}-->
            {{ $t('invoiceConnector.validation.theUserKeyFieldIsRequired') }}
          </span>
          </div>
          <div class="sm:col-span-1">
            <FormInput
                :label="$t('invoiceConnector.fields.fennoa.key')"
                inputName="userSecret"
                :value="form.userSecret"
                @handleInputChange="handleInputChange"
                :placeholder="isEditPage ? '(Encrypted)' : ''"
            />
            <span :class="v$.form.userSecret.$invalid ? 'invalid':''" v-if="v$.form.userSecret.$dirty && v$.form.userSecret.$invalid">
<!--            {{v$.form.userSecret.required.$message}}-->
            {{ $t('invoiceConnector.validation.theUserSecretFieldIsRequired') }}
          </span>
          </div>
        </template>
      </div>
      </template>
    </div>
  </div>
</template>

<script>
import AssigneeInvoiceConnectors from '@/components/SelectOptions/AssigneeInvoiceConnectors/AssigneeInvoiceConnectors';
import AttachmentInputField from '@/components/Main/AttachmentFiles/AttachmentInputField/AttachmentInputField';
import DeleteAttachmentDropDown
  from '@/components/Main/AttachmentFiles/AttachmentInputField/DeleteAttachmentDropDown/DeleteAttachmentDropDown';
import ShareSpace from '@/components/Main/ShareSpace/ShareSpace';
import EditDeleteShareSpaceDropDown
  from '@/components/Main/ShareSpace/EditDeleteShareSpaceDropDown/EditDeleteShareSpaceDropDown';
import { mapActions, mapGetters } from 'vuex';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import CommentList from '@/components/InvoiceConnectors/CommentList/CommentList';
import ErrorMessage from '../../../components/ErrorMessage/ErrorMessage';
import FormInput from '@/components/Main/Form/FormInput/FormInput';
import { EventBus } from '@/eventBus/event-bus';
import FormTextArea from '@/components/Main/Form/FormTextArea/FormTextArea';
import CommentBox from '@/components/Main/CommentBox/CommentBox';
import RightAside from '@/components/InvoiceConnectors/RightAside/RightAside';
import { Attachments } from '@/mixins/Attachments';
import { RequestInvoiceConnectorForm } from '@/mixins/InvoiceConnectors/RequestInvoiceConnectorForm';
import { withShareSpace } from '@/mixins/InvoiceConnectors/withShareSpace';
import CustomerApartments from '@/components/Apartments/CustomerApartments';
import FormTextAreaEditor from '@/components/Main/Form/FormTextArea/FormTextAreaEditor';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: 'CreateOrUpdate',
  mixins: [Attachments, RequestInvoiceConnectorForm, withShareSpace],
  setup() {
    return { v$: useVuelidate() }
  },
  components: {
    //CustomerApartments,
    FormTextAreaEditor,
    RightAside,
    CommentBox,
    FormTextArea,
    CommentList,
    EditDeleteShareSpaceDropDown,
    ShareSpace,
    DeleteAttachmentDropDown,
    AttachmentInputField,
    AssigneeInvoiceConnectors,
    DeleteItemModal,
    ErrorMessage,
    FormInput
  },
  data() {
    return {
      loading: false,
      pageUpdateAction: false,
      prefix: 'invoiceConnector',
      form: {
        id: null,
        name: '',
        userKey: '',
        userSecret: '',
        partnerId: '',
        partnerKey: '',
        language: '',
        organizationId: '',
        vendorId: 1,
        assignee_id: ''
      },
      selectedVendor:null,
      editVendorId: null,
      formErrors: {
        name: []
      }
    };
  },
  computed: {
    ...mapGetters([
      'invoiceConnector',
      'account',
      'updateInvoiceConnectorSuccess',
      'updateInvoiceConnectorError',
      'vendors'
    ]),

    isEditPage() {
      return this.$route.name === 'invoiceConnector-edit'
    }
  },
  watch: {
    invoiceConnector(data) {
      this.form = {
        id: data.id,
        name: data.name,
        userKey: data.user_key,
        userSecret: data.user_secret,
        partnerId: data.partner_id,
        partnerKey: data.partner_key,
        language: data.language,
        organizationId: data.organization_id,
        vendorId: data.vendor_id,
        assignee_id: ''
      };
      this.editVendorId = data.vendor_id
      this.loading = false;
      this.handleSetBreadCrumbItem(data.name);
    },
    updateInvoiceConnectorSuccess(newValue) {
      newValue && this.redirectToIndexPage();
    }
  },
  mounted() {
    this.handleSetBreadCrumbItems({ index: 0, to: '/invoice-connectors' });
    this.handleSetBreadCrumbItems({ index: 1, to: '' });
    const { id } = this.$route.params;
    if (id) {
      this.loading = true;
      this.pageUpdateAction = true;
      this.handleGetInvoiceConnector(id);
    }
    EventBus.$on('handleSaveForm', () => this.handleSubmit());
  },
  methods: {
    ...mapActions({
      handleGetInvoiceConnector: 'getInvoiceConnector',
      handleCreateAttachmentFile: 'createAttachmentFile',
      handleSetBreadCrumbItem: 'setBreadCrumbItem',
      handleSetBreadCrumbItems: 'setBreadCrumbItems',
      handleResetBreadCrumbItems: 'resetBreadCrumbItems'
    }),
    handleCloseModal() {
      this.showModal = false;
    },
    handleInputChange(inputName, value) {
      this.formErrors[inputName] = [];
      this.form[inputName] = value;
    },
    setVendor(vendor) {
      if (vendor.id !== this.editVendorId) {
        this.regenerateForm(vendor.id)
        return
      }

      this.handleGetInvoiceConnector(this.form.id)
    },
    regenerateForm(vendorId) {
      const id = this.form.id
      this.form = {
        id: id,
        name: '',
        userKey: '',
        userSecret: '',
        partnerId: '',
        partnerKey: '',
        language: '',
        organizationId: '',
        vendorId: vendorId,
        assignee_id: ''
      }
    },
    async handleSubmit() {
      const validate = await this.v$.$validate();
      if (!validate) {
        return
      }
      this.pageUpdateAction ? this.updateInvoiceConnector(this.form) : this.createInvoiceConnector();
    },
    addNewApartment(event) {
      this.form.apartments.push({
        id: Math.round(Math.random() * 1000000),
        title: event
      });
    },
    editApartment(event) {
      this.form.apartments.find(apartment => apartment.id === event.id).title = event.title;
    },
    deleteApartment(event) {
      this.form.apartments = this.form.apartments.filter(apartment => apartment.id !== event);
    }
  },
  destroyed() {
    this.handleResetBreadCrumbItems();
    EventBus.$off('handleSaveForm');
    this.$destroy();
  },
  validations() {
    if (this.form.vendorId === 1) {
      return {
        form: {
          name: { required },
          userKey: { required },
          userSecret:  this.isEditPage ? {} : { required }
        }
      }
    } else if (this.form.vendorId === 2) {
      return {
        form: {
          name: { required },
          userKey: { required },
          userSecret: this.isEditPage ? {} : { required },
          partnerId: { required },
          partnerKey: { required },
          language: { required },
          organizationId: { required }
        }
      }
    }
  }
};
</script>

<style scoped>
.invalid {
  color: red;
}
</style>

