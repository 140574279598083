<template>
  <div class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
    <div class="col-span-1">
      <AssigneeVendors
          @setVendor="$emit('setVendor', $event)"
          :defaultValue="String(form.vendorId)"
          :editable="editable"
          :vendors="vendors"
      />
    </div>
  </div>
</template>

<script>
import AttachmentInputField from '@/components/Main/AttachmentFiles/AttachmentInputField/AttachmentInputField';
import ShareSpace from '@/components/Main/ShareSpace/ShareSpace';
import AssigneeVendors from '@/components/SelectOptions/AssigneeVendors/AssigneeVendors';

export default {
  name: 'Vendors',
  components: {
    AssigneeVendors,
    ShareSpace,
    AttachmentInputField
  },
  props: {
    form: {
      type: Object,
      required: true
    },
    prefix: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    pageUpdateAction: {
      type: Boolean,
      'default': true
    },
    vendors: {
      type: Array
    }
  }
}
</script>

<style scoped>

</style>
