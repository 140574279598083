<template>
  <thead
      class="border-t border-gray-200 border-b border-gray-200 bg-gray-50 text-left text-xs text-gray-500 uppercase tracking-wider">
  <tr>
    <th class="whitespace-nowrap xxl:w-3/7 2xl:w-300p px-6 py-3">
      <div class="flex items-end cursor-pointer ml-1" @click="handleSort('name')">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('invoiceConnector.fields.name') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'name'" :arrowUp="arrowSortUp['name']"/>
        </div>
      </div>
    </th>
    <th class="whitespace-nowrap hidden xl:table-cell">
      <div class="flex justify-end w-full cursor-pointer items-end" @click="handleSort('vendor')">
        <div class="relative flex items-center flex-start pr-6 font-medium">
          {{ $t('invoiceConnector.fields.vendorName') }}
          <ArrowSolidIcon v-if="selectedSortingKey === 'vendor'" :arrowUp="!arrowSortUp['vendor']"/>
        </div>
      </div>
    </th>
    <th class="w-40p" />
    <!--      <th class="hidden xl:table-cell">-->
    <!--        <div class="flex justify-end w-full cursor-pointer" @click="handleSort('assigneeName')">-->
    <!--          <div class="relative flex items-center flex-start pr-6 font-medium">-->
    <!--            {{ $t('invoiceConnector.buttons.edit') }}-->
    <!--            <ArrowSolidIcon v-if="selectedSortingKey === 'assigneeName'" :arrowUp="!arrowSortUp['assigneeName']" />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </th>-->
    <!--      <th class="hidden xl:table-cell">-->
    <!--        <div class="flex justify-end w-full cursor-pointer" @click="handleSort('assigneeName')">-->
    <!--          <div class="relative flex items-center flex-start pr-6 font-medium">-->
    <!--            {{ $t('invoiceConnector.buttons.delete') }}-->
    <!--            <ArrowSolidIcon v-if="selectedSortingKey === 'assigneeName'" :arrowUp="!arrowSortUp['assigneeName']" />-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </th>-->
  </tr>
  </thead>
</template>

<script>
import ArrowSolidIcon from '@/components/Icons/ArrowSolidIcon';

export default {
  name: 'TableHead',
  components: {
    ArrowSolidIcon
  },
  data() {
    return {
      arrowSortUp: {
        name: true,
        vendor: true,
        contact: true,
        assigneeName: true
      },
      selectedSortingKey: 'name'
    }
  },
  methods: {
    handleSort(key) {
      this.selectedSortingKey = key;
      this.$emit('handleSort', key, this.arrowSortUp[key]);
      this.arrowSortUp[key] = !this.arrowSortUp[key];
    }
  }
}
</script>

<style scoped>

</style>
