<template>
  <div>
    <DeleteItemModal
        v-if="showModal"
        :title="$t('invoiceConnector.deleteInvoiceConnectorModal.title')"
        :description="$t('invoiceConnector.deleteInvoiceConnectorModal.description')"
        @handleCloseModal="handleCloseModal"
        @handleConfirm="deleteInvoiceConnector"
    />
    <div class="relative" v-if="!loading">
      <DataTableSearch @handleSearchTable="handleSearchTable"/>
      <Table
          @handleSort="handleSort"
          :dataTable="dataTable"
          @handleOpenDeleteInvoiceConnectorModal="handleOpenDeleteInvoiceConnectorModal"
      />
      <Pagination :count=count @handlePaginate="handlePaginate"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DeleteItemModal from '@/components/Modals/DeleteItemModal/DeleteItemModal';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import Pagination from '@/components/Main/Pagination/Pagination';
import handleTableSort from '@/helpers/handleTableSort';
import EditActions from '@/components/Table/EditActions/EditActions';
import TableHead from '@/components/InvoiceConnectors/Table/TableHead/TableHead';
import TableBody from '@/components/InvoiceConnectors/Table/TableBody/TableBody';
import Table from '@/components/InvoiceConnectors/Table/Table';

export default {
  name: 'InvoiceConnectorsTable',
  components: {
    Table,
    TableBody,
    EditActions,
    Pagination,
    DataTableSearch,
    DeleteItemModal,
    TableHead
  },
  data() {
    return {
      count: 0,
      showModal: false,
      data: [],
      dataTable: [],
      loading: true,
      searchValue: '',
      selectedInvoiceConnectorId: ''
    }
  },
  computed: {
    ...mapGetters(['invoiceConnectors'])
  },
  watch: {
    invoiceConnectors({ invoiceConnectors }) {
      this.data = invoiceConnectors;
      this.loading = false;
    },
    data(newValue) {
      this.count = newValue.length;
      this.dataTable = newValue.slice(0, 30);
      /*this.dataTable.map((item) => {
        const vendorName = item.vendor.name
        return item.vendor = vendorName
      })*/
    }
  },
  mounted() {
    this.getInvoiceConnectors();
  },
  methods: {
    ...mapActions(['getInvoiceConnectors', 'fetchDeleteInvoiceConnector']),
    handleSort(key, sorting) {
      handleTableSort(this, key, sorting);
    },
    handleSearchTable(value) {
      this.searchValue = value;
      this.dataTable = this.data.filter((invoiceConnector) => {
        if ((invoiceConnector.name && invoiceConnector.name.toLowerCase().includes(value)) || (invoiceConnector.address && invoiceConnector.address.toLowerCase().includes(value)) || (invoiceConnector.contact && invoiceConnector.contact.toLowerCase().includes(value))) {
          return invoiceConnector;
        }
      })
      this.dataTable = this.dataTable.slice(0, 30);
    },
    handlePaginate(page, defaultPageSlice) {
      const copyProducts = [...this.data];
      this.dataTable = copyProducts.slice(page, defaultPageSlice);
    },
    handleOpenDeleteInvoiceConnectorModal(invoiceConnectorId) {
      this.selectedInvoiceConnectorId = invoiceConnectorId;
      this.showModal = true;
    },
    handleCloseModal() {
      this.showModal = false;
    },
    deleteInvoiceConnector() {
      this.fetchDeleteInvoiceConnector({ id: this.selectedInvoiceConnectorId }).then(response => {
        this.toastMessage(response.message, 'success');
      }).catch(error => {
        this.toastMessage(error.message, 'error');
      });
      this.showModal = false;
      this.data = this.data.filter(product => product.id !== this.selectedInvoiceConnectorId);
    },
    toastMessage(message, type) {
      this.$toast.open({ message, type });
    }
  }
}
</script>

<style scoped>

</style>
